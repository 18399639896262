import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import {Grid, Typography} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import theme from "../assets/theme";

const NotFoundPage = ({ data, location }) => {
  return (
      <ThemeProvider theme={theme}>
        <Layout>
          <Grid
              container
              item
              xs={12}
              alignItems="center"
              justify="center"
              direction="column"
          >
            <h1>404: Not Found</h1>
            <Link to="/">
              <Typography variant="b1">Back to Home</Typography>
            </Link>
          </Grid>
        </Layout>
      </ThemeProvider>
  )
}

export default NotFoundPage